<template>
  <div>
    <!-- 已到账3 -->
    <withdrawal-table :auditStatus="3"></withdrawal-table>
  </div>
</template>
<script>
import withdrawalTable from '@/components/supplier/withdrawalTable.vue'
export default {
  components: { withdrawalTable },
  data() {
    return {
     
    }
  }
}
</script>
<style lang="scss" scoped>

</style>